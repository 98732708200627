import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { debounce, getRandomStr } from 'dpl/shared/utils';
import FancyDropdown from 'dpl/components/FancyDropdown';
import withToggle from 'dpl/decorators/withToggle';

class Search extends Component {
  static propTypes = {
    inputClassName: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    toggleOff: PropTypes.func.isRequired,
    toggleOn: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    ResultsComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
      .isRequired,
    query: PropTypes.string,
    timeout: PropTypes.number
  };

  static defaultProps = {
    onChange: () => {},
    query: '',
    timeout: 100
  };

  state = {
    query: this.props.query
  };

  idSuffix = getRandomStr();

  inputRef = createRef();

  handleFocus = () => {
    this.props.toggleOn();
    this.inputRef.current.select();
  };

  updateQuery = debounce(query => {
    this.setState({ query });
  }, this.props.timeout);

  handleInputChange = e => {
    this.updateQuery(e.target.value);
  };

  updateInputValue = value => {
    this.inputRef.current.value = value;
    this.updateQuery(value);
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen, query } = this.props;

    if (prevProps.isOpen !== isOpen) {
      if (!isOpen) {
        // handle closing dropdown w esc
        this.inputRef.current.blur();
        this.updateInputValue(query);
      } else {
        this.inputRef.current.focus();
      }

      this.props.onChange(isOpen);
    }

    if (query !== prevProps.query) {
      this.updateInputValue(query);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { query } = this.state;
    const { isOpen, inputClassName } = this.props;

    return window.__DPL_BOT_UA
      ? false
      : nextProps.isOpen !== isOpen ||
          nextState.query !== query ||
          nextProps.query !== query ||
          nextProps.inputClassName !== inputClassName;
  }

  render() {
    const { query } = this.state;
    const {
      placeholder,
      inputClassName,
      isOpen,
      toggle,
      toggleOff,
      ResultsComponent,
      ...resultsComponentProps
    } = this.props;

    return (
      <div className="Search">
        <FancyDropdown isOpen={isOpen} onChange={toggle}>
          <input
            ref={this.inputRef}
            id={`search-input-${this.idSuffix}`}
            aria-label={placeholder}
            type="text"
            placeholder={placeholder}
            className={inputClassName}
            onChange={this.handleInputChange}
            onFocus={this.handleFocus}
            onBlur={toggleOff}
            name="breed_name"
            autoComplete="off"
            defaultValue={query}
          />
          <div role="presentation" onMouseDown={e => e.preventDefault()}>
            <ResultsComponent
              {...resultsComponentProps}
              query={query}
              isOpen={isOpen}
            />
          </div>
        </FancyDropdown>
      </div>
    );
  }
}

export default withToggle({ propName: 'isOpen' })(Search);
