import { connect } from 'react-redux';

import { ORDERED_SEARCH_TYPES } from 'dpl/shared/constants/shared';
import { toggleMobileMenu } from 'dpl/actions/mobile_menu';

export const MENU_TYPES = {
  NAVIGATION: 'NAVIGATION',
  SEARCH: 'SEARCH',
  BREEDER_NEWS: 'BREEDER_NEWS'
};

export default function withMobileMenu(WrappedComponent) {
  return connect(
    ({ mobileMenu: { openMobileMenuName, selectedSearchType } }) => ({
      openMobileMenuName,
      selectedSearchType,
      searchType: selectedSearchType
    }),
    {
      closeMenu: () => toggleMobileMenu(null),
      openNavigationMenu: () => toggleMobileMenu(MENU_TYPES.NAVIGATION),
      openSearchBreedersMenu: () =>
        toggleMobileMenu(MENU_TYPES.SEARCH, ORDERED_SEARCH_TYPES.BREEDERS),
      openSearchSheltersMenu: () =>
        toggleMobileMenu(MENU_TYPES.SEARCH, ORDERED_SEARCH_TYPES.SHELTERS),
      openBreederNewsMenu: () => toggleMobileMenu(MENU_TYPES.BREEDER_NEWS)
    }
  )(WrappedComponent);
}
