import React from 'react';
import PropTypes from 'prop-types';

import Search from 'dpl/components/Search';
import BreedSearchResultsContainer from 'dpl/containers/BreedSearchResultsContainer';

export default function BreedSearch({ className, ...rest }) {
  return (
    <div className="BreedSearch">
      <Search
        {...rest}
        inputClassName={className}
        ResultsComponent={BreedSearchResultsContainer}
        timeout={300}
      />
    </div>
  );
}

BreedSearch.propTypes = {
  className: PropTypes.string
};

BreedSearch.defaultProps = {
  className: null
};
