import React from 'react';

import withGooglePlaces from 'dpl/decorators/withGooglePlaces';
import LocationResultsList from 'dpl/search/components/LocationResultsList';

function LocationResultsListContainer(props) {
  return <LocationResultsList {...props} />;
}

export default withGooglePlaces(LocationResultsListContainer);
