import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { debounce } from 'dpl/shared/utils';

const DEFAULT_ITEM_PADDING = 24;
const isTestEnv = CONFIG.isTest;

export default class TransitionMenu extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    onItemClick: PropTypes.func.isRequired,
    itemClassName: PropTypes.string,
    selectedIdx: PropTypes.number.isRequired,
    equalWidths: PropTypes.bool,
    itemPadding: PropTypes.number,
    hideBorder: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    customColors: PropTypes.shape({
      borderColorClassName: PropTypes.string,
      selectedColorClassName: PropTypes.string,
      itemColorClassName: PropTypes.string
    })
  };

  static defaultProps = {
    itemClassName: null,
    equalWidths: false,
    hideBorder: false,
    itemPadding: DEFAULT_ITEM_PADDING,
    className: null,
    color: 'stone-700',
    customColors: {}
  };

  itemPadding = this.props.equalWidths ? 0 : this.props.itemPadding;

  itemRefs = [];
  borderRef = null;

  updateBorder = debounce(() => {
    const { selectedIdx } = this.props;

    if (isTestEnv || selectedIdx < 0 || !this.borderRef) {
      return;
    }

    const left = this.itemRefs
      .slice(0, selectedIdx + 1)
      .reduce((_left, item, idx) => {
        if (!item.width) {
          item.width = item.el.clientWidth - this.itemPadding * 2;
        }

        return idx === selectedIdx
          ? _left
          : _left + item.width + this.itemPadding * 2;
      }, 0);

    Object.assign(this.borderRef.style, {
      width: `${this.itemRefs[selectedIdx].width}px`,
      left: `${left}px`
    });
  }, 100);

  componentDidMount() {
    this.updateBorder();
  }

  componentDidUpdate() {
    this.updateBorder();
  }

  render() {
    const {
      items,
      onItemClick,
      itemClassName,
      equalWidths,
      hideBorder,
      className,
      selectedIdx,
      color,
      customColors
    } = this.props;

    const { borderColorClassName, selectedColorClassName, itemColorClassName } =
      customColors;

    const borderColor = borderColorClassName || `bg-${color}`;
    const selectedColor = selectedColorClassName || color;

    return (
      <div className={classnames('TransitionMenu relative', className)}>
        <ul className="flex pb1" style={{ margin: `0 -${this.itemPadding}px` }}>
          {items.map((item, idx) => (
            <li
              key={idx}
              className={classnames({
                'w-100': equalWidths
              })}
              ref={el => {
                this.itemRefs[idx] = { el };
              }}
            >
              <button
                type="button"
                data-test-id={`transition-menu-button-${item}`}
                className={classnames('nowrap w-100 tc', itemClassName, {
                  [selectedColor]: selectedIdx === idx,
                  [itemColorClassName]: selectedIdx !== idx
                })}
                style={{
                  paddingLeft: `${this.itemPadding}px`,
                  paddingRight: `${this.itemPadding}px`
                }}
                onMouseDown={e => onItemClick(e, idx)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
        {!hideBorder && (
          <div>
            <span
              ref={el => {
                this.borderRef = el;
              }}
              className={classnames(
                'TransitionMenu__border absolute transition ',
                borderColor
              )}
            />
          </div>
        )}
      </div>
    );
  }
}
